import React, {useContext, useEffect, useRef} from "react";
import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";
import {TestcaseContext} from "../context/testcaseContext";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props, cameraId) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio || 1
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }

    if (cameraId) {
        config.videConstraints = {deviceId: cameraId}
    }

    config.focusMode = "continuous";
    config.useBarCodeDetectorIfSupported = true;
    config.defaultZoomValueIfSupported = 2;

    return config;
};

const Html5QrcodePlugin = (props) => {
    const scannerRef = useRef(null); // Reference to the Html5Qrcode instance
    const isRunningRef = useRef(false); // Tracks if a scan is ongoing

    const {setCameras, cameras, selectedCamera, setSelectedCamera} = useContext(TestcaseContext);

    useEffect(() => {
        Html5Qrcode.getCameras()
            .then(devices => {
                if (devices && devices.length > 0) {
                    setCameras(devices);
                    const lastCamera = devices[devices.length - 1];
                    setSelectedCamera(lastCamera);
                }
            })
    }, []);

    useEffect(() => {
        if (!selectedCamera) {
            return;
        }

        const html5QrcodeInstance = scannerRef.current || new Html5Qrcode(qrcodeRegionId);
        scannerRef.current = html5QrcodeInstance; // Ensure we reuse the same instance

        const startScanner = async () => {
            try {
                const config = createConfig(props, selectedCamera.id);
                await html5QrcodeInstance.start(
                    {deviceId: {exact: selectedCamera.id}},
                    config,
                    props.qrCodeSuccessCallback,
                    props.qrCodeErrorCallback
                );
                isRunningRef.current = true; // Mark scanner as running
            } catch (error) {
                console.error("Failed to start scanner:", error);
            }
        };

        const stopScannerIfRunning = async () => {
            if (isRunningRef.current) {
                try {
                    await html5QrcodeInstance.stop();
                    await html5QrcodeInstance.clear();
                    isRunningRef.current = false;
                } catch (err) {
                    console.error("Failed to stop scanner:", err);
                }
            }
        };

        if (props.isScanning) {
            // Start scanner when flag is true
            startScanner();
        } else {
            // Stop scanner when flag is false
            stopScannerIfRunning();
        }


        // Only stop the scanner if it's already running
        stopScannerIfRunning().then(() => startScanner());

        // Cleanup when component unmounts or selectedCamera changes
        return () => {
            stopScannerIfRunning();
        };
    }, [selectedCamera, props.isScanning]);

    useEffect(() => {
        if (!selectedCamera) {
            return
        }

        const qrcodeRegion = document.getElementById(qrcodeRegionId);
        if (qrcodeRegion) {
            qrcodeRegion.style.border = "none";
            qrcodeRegion.style.width = "600px";
        }

        /*let qrcode_scan_region = document.getElementById("html5qr-code-full-region__scan_region");
        let flipIcon = document.createElement("img");
        flipIcon.src = "/images/basic-change-camera-outline.svg";
        flipIcon.alt = "flip_camera_icon";
        flipIcon.style.position = "absolute";
        qrcode_scan_region.appendChild(flipIcon)*/


        let qrcode_dashboardRegion = document.getElementById("html5qr-code-full-region__dashboard");
        if (qrcode_dashboardRegion) {
            qrcode_dashboardRegion.style.visibility = "hidden";
        }
        //qrcode_dashboardRegion.style.display = "none";

        const images = document.querySelectorAll("#html5qr-code-full-region img");
        images.forEach((img) => {
            img.style.display = "none";
        });

        const images2 = document.querySelectorAll("#html5qr-code-full-region__scan_region img");
        images2.forEach((img) => {
            img.style.display = "none";
        });
    }, [selectedCamera]);

    return <div id={qrcodeRegionId}/>
};

export default Html5QrcodePlugin;
